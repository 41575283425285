<template>
  <div class="humansegmentation">
    <div
      class="canvensBox"
      style="position: fixed; top: 999999999999999999999px"
    >
      <canvas
        id="myCanvas"
        class="canvas"
        :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }"
      ></canvas>
    </div>
    <div class="modelBox" v-if="windowWidth">
      <span v-show="language_type" class="modelTitle">模型选择：</span>
      <span v-show="!language_type" class="modelTitle">Model selection：</span>
      <div
        :class="PitchOn_first ? 'PitchOn_first' : 'modelSelect'"
        @click="modelFirst"
      >
        V1.1
      </div>
      <div
        :class="PitchOn_last ? 'PitchOn_last' : 'modelSelect'"
        @click="modelLast"
      >
        V1.2
      </div>
    </div>
    <div class="functionBox" v-if="windowWidth">
      <div
        class="functionLeft"
        v-loading="loading"
        element-loading-background="#e2e8ef"
      >
        <div class="oldimgUrl" style="width: 50%" ref="widthStyle">
          <p class="beforelabel" v-show="language_type">抠图前</p>
          <p class="beforelabel en_long" v-show="!language_type">Before</p>
          <!-- <img :src="imgUrl" alt="" class="imgUrl" /> -->
          <el-image
            class="imgUrl"
            :src="imgUrl"
            :preview-src-list="srcList"
            @click="getSrc(imgUrl)"
          >
          </el-image>
        </div>
        <div class="newimgUrl" :style="bg_size">
          <!-- <img :src="NewimgUrl" alt="" class="imgUrl" /> -->
          <el-image
            class="imgUrl"
            :src="NewimgUrl"
            :preview-src-list="srcList"
            @click="getSrcS(NewimgUrl)"
          >
          </el-image>
          <!-- <img
            class="arcLogo"
            src="../../../assets/img/shuiyin_logo2.png"
            alt=""
          /> -->
        </div>
        <el-slider
          v-model="value"
          :show-tooltip="false"
          @input="slideImg"
          @change="mouseMove"
        ></el-slider>
        <div class="slideRectangle" style="left: 50%" ref="leftStyle">
          <div class="Slice">
            <img src="../../../assets/img/Slice2.png" alt="" />
          </div>
          <div class="Rectangle">
            <img src="../../../assets/img/Rectangle2765.png" alt="" />
          </div>
          <p class="afterlabel" v-show="language_type">抠图后</p>
          <p class="afterlabel en_long" v-show="!language_type">After</p>
        </div>
      </div>
      <div class="functionRight">
        <img
          @click="changImg(item, index)"
          class="imgList"
          v-for="(item, index) in imgList"
          :key="index"
          :src="item.url"
          alt=""
          :class="{ active: index === isActice }"
        />
      </div>
      <div class="btnBox">
        <div class="btnLeft"></div>
        <div class="btnRight">
          <span class="shareTo" v-show="language_type">分享至：</span>
          <span class="shareTo" v-show="!language_type">Share To :</span>
          <div class="shareIcon" @click="wxShareAppMessage">
            <img
              class="normal_img"
              src="../../../assets/img/icon/wx1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/wx.png"
              alt=""
            />
            <div class="wx_code">
              <div class="code" v-show="language_type" ref="qrCodeUrl"></div>
              <div class="code" v-show="!language_type" ref="qrCodeUrl2"></div>
              <p v-show="language_type">分享到微信朋友圈</p>
              <p v-show="!language_type">Share with wechat</p>
            </div>
          </div>
          <div class="shareIcon" @click="share_weibo">
            <img
              class="normal_img"
              src="../../../assets/img/icon/weibo1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/weibo.png"
              alt=""
            />
          </div>
          <div class="shareIcon" @click="shareToQQ">
            <img
              class="normal_img"
              src="../../../assets/img/icon/qq1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/qq.png"
              alt=""
            />
          </div>
          <!-- <div class="shareIcon" @click="shareToTieba">
            <img
              class="normal_img"
              src="../../../assets/img/icon/zhihu1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/zhihu.png"
              alt=""
            />
          </div>
          <div class="shareIcon" @click="shareToFacebook">
            <img
              class="normal_img"
              src="../../../assets/img/icon/bilibili1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/bilibili.png"
              alt=""
            />
          </div> -->
          <div class="shareIcon" @click="shareToDouban">
            <img
              class="normal_img"
              src="../../../assets/img/icon/dou1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/dou.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="functionBox_mini" v-if="windowWidth == false">
      <p class="message" v-if="language_type">
        建议在PC端打开链接或扫描二维码体验Demo
      </p>
      <p class="message" v-if="!language_type">
        It is recommended to open the link or scan the QR code on the PC side to
        experience the Demo
      </p>
      <div class="wxapp_box">
        <img src="../../../assets/img/wxapp.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  data() {
    return {
      windowWidth: true,
      imgList: [
        {
          index: 1,
          url: "https://50058.gzc.svp.tencent-cloud.com/0b532aac4aaagealqzg76nqyvugdf3iaalqa.f0.jpg?dis_k=6a6f1bc6c219f527b0f556dcc3a7c6d1&dis_t=1642043105",
          url2: "https://50058.gzc.svp.tencent-cloud.com/0b53xmacsaaataal4lo72rqyvo6dfg5qakia.f0.jpg?dis_k=ef8555284c6c0a73e07db2ca66d43684&dis_t=1642043171",
          url3: "https://50058.gzc.svp.tencent-cloud.com/0b53yyahwaaamuaaja4vgrrivrwdppdaa6ya.f0.jpg?dis_k=c71b3b9ef08f1b3657598967583dd5fe&dis_t=1653964707",
        },
        {
          index: 2,
          url: "https://50058.gzc.svp.tencent-cloud.com/0b53riacsaaapmal6s67fbqyvcwdfgfaakia.f0.jpg?dis_k=dc06ab70b96ce4895f9cfc9c0baf33bb&dis_t=1642043122",
          url2: "https://50058.gzc.svp.tencent-cloud.com/0b53vaacqaaasaal77w7ffqyvkgdfcuaakaa.f0.jpg?dis_k=e13d9912e0f569c7f2bf6fd343916e4f&dis_t=1642043192",
          url3: "https://50058.gzc.svp.tencent-cloud.com/0b53f4a6maabxyajt6euczriwl6d4yxqdzqa.f0.jpg?dis_k=f651293078c21e41e2a1b2af905de052&dis_t=1653964689",
        },
        {
          index: 3,
          url: "https://50058.gzc.svp.tencent-cloud.com/0b534aadaaaalaamj7xaz5qyvygdgdqaamaa.f0.jpg?dis_k=463f3a79e3c12fec6fe73289dccf5743&dis_t=1642132664",
          url2: "https://50058.gzc.svp.tencent-cloud.com/0b53uaadoaaahmadtihbzvqyvigdg6qaanya.f0.jpg?dis_k=d8ad8e286a6791b93d5fc6277587db57&dis_t=1642139291",
          url3: "https://50058.gzc.svp.tencent-cloud.com/0b534ea7iaabsmaiwx4uc5rixyod6tqqd5aa.f0.jpg?dis_k=05cacd15820b9cf1b008828466ba085f&dis_t=1653964658",
        },
      ],
      //旧照片
      imgUrl:
        "https://50058.gzc.svp.tencent-cloud.com/0b532aac4aaagealqzg76nqyvugdf3iaalqa.f0.jpg?dis_k=6a6f1bc6c219f527b0f556dcc3a7c6d1&dis_t=1642043105",
      //新照片
      NewimgUrl:
        "https://50058.gzc.svp.tencent-cloud.com/0b53xmacsaaataal4lo72rqyvo6dfg5qakia.f0.jpg?dis_k=ef8555284c6c0a73e07db2ca66d43684&dis_t=1642043171",
      isActice: 0,
      value: 50,
      suffix: "", //文件拓展名
      ImgName: "", //文件名
      strImgName: "",
      downImg: "",
      uploadtake: false,
      dowmtake: false,
      mouse_disable: false,
      first: 1,
      bg_width: 98,
      bg_height: 100,
      loading: false,
      bg_size: "background-size:98% 100%",
      language_type: true,
      canvasWidth: 0,
      canvasHeight: 0,
      srcList: [],
      //模型选择
      PitchOn_first: true,
      PitchOn_last: false,
      parameter: "abc",
      file: "",
    };
  },
  mounted() {
    if (window.innerWidth > 1032) {
      this.creatQrCode();
    }
    if (this.$route.path.slice(1, 3) == "zh") {
      this.language_type = true;
    } else {
      this.language_type = false;
    }
    this.createBtn()
  },
  watch: {
    $route(to) {
      if (to.path.slice(1, 3) == "zh") {
        this.language_type = true;
      } else {
        this.language_type = false;
      }
    },
  },
  methods: {
    createBtn() {
      let btnHtml = `<div class="uploadBtn">
      ${this.language_type ? "本地上传" : "Upload"}
      <input
        type="file"
        accept="image/*"
        ref="avatarInput"
        multiple
        class="uploadInput"
      />
      </div>
      <div
        class="${this.mouse_disable ? "mouse_disable" : "dowmBtn"} downBtn">
        ${this.language_type ? "下载图片" : "Download"}
      </div>`;
      let btnLeft = document.getElementsByClassName("btnLeft")[0];
      btnLeft.innerHTML = btnHtml;
      let input = document.getElementsByClassName("uploadInput");
      input[0].addEventListener("change", this.uploadImage);
      let down = document.getElementsByClassName("downBtn");
      down[0].addEventListener("click", this.downloadIamge);
    },
    modelFirst() {
      this.PitchOn_first = true;
      this.PitchOn_last = false;
      this.parameter = "abc";
      this.sendFn();
      if (this.parameter == "abc") {
        this.NewimgUrl = this.imgList[this.isActice].url2;
      }
    },
    modelLast() {
      this.PitchOn_last = true;
      this.PitchOn_first = false;
      this.parameter = "def";
      this.sendFn();
      if (this.parameter == "def") {
        this.NewimgUrl = this.imgList[this.isActice].url3;
      }
    },
    sendFn() {
      if (this.file !== "") {
        this.uploadImage(this.file);
      }
    },
    getSrc(imgUrl) {
      this.srcList = [];
      this.srcList.push(imgUrl);
    },
    getSrcS(NewimgUrl) {
      this.srcList = [];
      this.srcList.push(NewimgUrl);
    },
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    changImg(item, index) {
      this.imgUrl = item.url;
      if (this.parameter == "abc") {
        this.NewimgUrl = item.url2;
      } else {
        this.NewimgUrl = item.url3;
      }
      this.isActice = index;
      this.bg_size = "background-size:98% 100%";
      this.file = "";
    },
    uploadImage(e) {
      var that = this;
      that.uploadtake = true;
      this.file = e;
      var file = e.target.files[0];

      var model = "False";
      if (this.parameter == "abc") {
        model = "False";
      }
      if (this.parameter == "def") {
        model = "True";
      }
      that.ImgName = file.name; //获取文件名称
      that.suffix = that.ImgName.substring(that.ImgName.lastIndexOf(".") + 1); //获取拓展名
      that.strImgName = that.ImgName.split(".")[0];
      let imgT =
        "https://50058.gzc.svp.tencent-cloud.com/0b53oibwiaadxaaozsg3xjrys4wdmrzagzaa.f0.png?dis_k=867894a49d34b36a9749f3589af8fcf1&dis_t=1675135178";
      let imageT = new Image();
      imageT.src = imgT;
      imageT.setAttribute("crossOrigin", "Anonymous");
      imageT.onload = function () {
        var base64 = that.getBase64Image(imageT);
        // document.getElementById("img")['src'] = base64;
        //转换base64到file
        var file2 = that.btof(base64, "test");
        var reader = new FileReader();
        reader.onloadend = function () {
          that.loading = true;
          var forData = new FormData();
          forData.append("file", file);
          forData.append("file2", file2);
          forData.append("matting_refine", model);
          forData.append("complate", model);
          let potss = forData;
          let urls = "https://arc.tencent.com/human_fenge/";
          that.axios
            .post(urls, potss)
            .then((res) => {
              if (res.data.code == "0") {
                let img = new Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = function () {
                  if (img.width * 415 > img.height * 740) {
                    let bg_height = (740 * img.height) / img.width / 4.15;
                    that.bg_size = "background-size:" + "98%" + bg_height + "%";
                  } else {
                    let bg_width = (415 * img.width) / img.height / 7.4;
                    that.bg_size = "background-size:" + bg_width + "% 100%";
                  }
                };
                that.NewimgUrl = res.data.data[0].image_url;
                var baseimg = res.data.data[0].image_base64;
                that.downImg = baseimg;
                that.imgUrl = this.result;
                that.loading = false;
                that.$message({
                  type: "success",
                  message: that.language_type ? "加载成功" : "Success",
                });
              } else {
                that.loading = false;
                that.$message({
                  type: "error",
                  message: that.language_type
                    ? "返回结果失败，请稍后再试"
                    : "Failed to return results. Please try again later",
                });
              }
            })
            .catch(() => {
              that.loading = false;
              that.$message({
                type: "error",
                message: that.language_type
                  ? "当前调用的人太多啦，请稍后再试"
                  : "Too many people are calling now. Please try again later",
              });
              that.imgUrl = that.imgList[that.isActice].url;
              that.NewimgUrl = that.imgList[that.isActice].url2;
            });
        };
        reader.readAsDataURL(file);
      };
    },
    downloadIamge() {
      if (this.downImg.length == "0") {
        this.$message({
          type: "warning",
          message: this.language_type
            ? "请先上传图片"
            : "Please upload pictures first",
        });
      } else {
        this.dowmtake = true;
        var Namesuffix = this.language_type ? "_抠图后" : "_after";
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          let bstr = atob(this.downImg.split(",")[1]);
          let n = bstr.length;
          let u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          let blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(
            blob,
            this.strImgName + Namesuffix + "." + this.suffix
          );
        } else {
          // 这里就按照chrome等新版浏览器来处理
          let a = document.createElement("a");
          a.href = this.downImg;
          a.setAttribute(
            "download",
            this.strImgName + Namesuffix + "." + this.suffix
          );
          a.click();
        }
      }
    },
    slideImg() {
      this.$refs.widthStyle.style.width = this.value + "%";
      this.$refs.leftStyle.style.left = this.value + "%";
      if (this.first == 1) {
        this.first++;
      } else {
        // 鼠标滑过效果互斥
        this.mouse_disable = true;
      }
    },
    mouseMove() {
      this.mouse_disable = false;
    },
    // 创建微信二维码
    creatQrCode() {
      var wx_qr = document.getElementsByClassName("code");
      var zh_path, en_path;
      if (this.$route.path.slice(1, 3) == "zh") {
        zh_path = this.$route.path;
        en_path = this.$route.path.replace("/zh/", "/en/");
      } else {
        zh_path = this.$route.path.replace("/en/", "/zh/");
        en_path = this.$route.path;
      }
      var path = "https://arc.tencent.com" + zh_path;
      var path2 = "https://arc.tencent.com" + en_path;
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: path, // 需要转换为二维码的内容
        width: wx_qr[0].offsetWidth,
        height: wx_qr[0].offsetHeight,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      var qrcode2 = new QRCode(this.$refs.qrCodeUrl2, {
        text: path2, // 需要转换为二维码的内容
        width: wx_qr[0].offsetWidth,
        height: wx_qr[0].offsetHeight,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(qrcode, qrcode2);
    },
    wxShareAppMessage() {
      wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx80d0a7314195c39e", // 必填，公众号的唯一标识
        timestamp: "1414587457", // 必填，生成签名的时间戳
        nonceStr: "Wm3WZYTPz0wzccnW", // 必填，生成签名的随机串
        signature: "0f9de62fce790f9a083d5c99e95740ceb90c27ed", // 必填，签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: "人像抠图", // 分享标题
          desc: "自动抠出前景人像图", // 分享描述
          link: "https://arc.tencent.com/ai-demos/humansegmentation", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://50058.gzc.svp.tencent-cloud.com/0b53lqadaaaat4ajeuavpzriuxgdgboaamaa.f0.jpg?dis_k=d924b0483964c3a9c031c8889a0c4c47&dis_t=1645585091", // 分享图标
          success: function () {
            alert("分享成功");
          },
          cancel: function () {
            alert("分享失败");
          },
        });
        wx.updateTimelineShareData({
          title: "人像抠图-自动抠出前景人像图", // 分享标题
          link: "https://arc.tencent.com/ai-demos/humansegmentation", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://50058.gzc.svp.tencent-cloud.com/0b53lqadaaaat4ajeuavpzriuxgdgboaamaa.f0.jpg?dis_k=d924b0483964c3a9c031c8889a0c4c47&dis_t=1645585091", // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });

      wx.error(function () {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        alert("验证失败");
      });
    },
    // 分享到新浪微博
    share_weibo(event) {
      event.preventDefault();
      // shareUrl是微博的分享地址，（有资料说需要真实的appkey，必选参数，这里我没用appkey也可以正常分享）
      var _shareUrl = "http://v.t.sina.com.cn/share/share.php?";
      _shareUrl +=
        "url=" +
        encodeURIComponent(
          "https://arc.tencent.com/ai-demos/humansegmentation"
        ); //参数url设置分享的内容链接
      _shareUrl +=
        "&title=" + encodeURIComponent("人像抠图-自动抠出前景人像图"); //参数title设置分享的标题
      _shareUrl +=
        "&pic=" + encodeURIComponent(this.NewimgUrl) + "&searchPic=true";
      // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
      window.open(
        _shareUrl,
        "_blank",
        "height=400, width=400",
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    shareToQQ() {
      //此处分享链接内无法携带图片
      const share = {
        title: "人像抠图",
        desc: "自动抠出前景人像",
        share_url: "https://arc.tencent.com/ai-demos/humansegmentation",
      };
      var _shareUrl =
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
        encodeURIComponent(share.share_url) +
        "&title=" +
        share.title +
        "&desc=" +
        share.desc;
      window.open(
        _shareUrl,
        "打开qq分享",
        "height=700, width=800,left=500,top=200",
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    //分享到贴吧
    shareToTieba(event) {
      event.preventDefault();
      var _shareUrl = "http://tieba.baidu.com/f/commit/share/openShareApi?";
      const share = {
        title: "人像抠图-自动抠出前景人像图",
        share_url: "https://arc.tencent.com/ai-demos/humansegmentation",
        pic: this.NewimgUrl,
        _width: 670,
        _height: 550,
        _top: (screen.height - 550) / 2,
        _left: (screen.width - 670) / 2,
      };
      _shareUrl += "title=" + encodeURIComponent(share.title || document.title); //分享的标题
      _shareUrl +=
        "&url=" + encodeURIComponent(share.share_url || document.location); //分享的链接
      _shareUrl += "&pic=" + encodeURIComponent(share.pic || ""); //分享的图片
      window.open(
        _shareUrl,
        "_blank",
        "width=" +
          share._width +
          ",height=" +
          share._height +
          ",left=" +
          share._left +
          ",top=" +
          share._top +
          ",toolbar=no,menubar=no,scrollbars=no,resizable=1,location=no,status=0"
      );
    },
    //分享到Facebook
    shareToFacebook(event) {
      event.preventDefault();
      var _shareUrl = "http://www.facebook.com/sharer/sharer.php?";
      const share = {
        title: "人像抠图-自动抠出前景人像",
        share_url: "https://arc.tencent.com/ai-demos/humansegmentation",
        _width: 670,
        _height: 550,
        _top: (screen.height - 550) / 2,
        _left: (screen.width - 670) / 2,
      };
      _shareUrl += "u=" + encodeURIComponent(share.share_url || location.href); //分享的链接
      _shareUrl += "&t=" + encodeURIComponent(share.title || document.title); //分享的标题
      window.open(
        _shareUrl,
        "_blank",
        "width=" +
          share._width +
          ",height=" +
          share._height +
          ",left=" +
          share._left +
          ",top=" +
          share._top +
          ",toolbar=no,menubar=no,scrollbars=no,resizable=1,location=no,status=0"
      );
    },
    //分享到豆瓣
    shareToDouban(event) {
      event.preventDefault();
      const share = {
        title: "人像抠图-自动抠出前景人像",
        share_url: "https://arc.tencent.com/ai-demos/humansegmentation",
        pic: this.NewimgUrl,
      };
      var _shareUrl = "http://shuo.douban.com/!service/share?";
      _shareUrl +=
        "href=" + encodeURIComponent(share.share_url || location.href); //分享的链接
      _shareUrl += "&name=" + encodeURIComponent(share.title || document.title); //分享的标题
      _shareUrl += "&image=" + encodeURIComponent(share.pic || ""); //分享的图片
      window.open(_shareUrl, "_blank");
    },
    // 改变arcLogo的大小、位置
    // changeArea(url) {
    //   let logo = document.getElementsByClassName("arcLogo")[0];
    //   let mar_top = 10;
    //   let mar_left = 10;
    //   let pix = 1;
    //   let img = new Image();
    //   img.src = url;
    //   img.onload = function () {
    //     if (img.width * 415 > img.height * 740) {
    //       mar_top = (415 - (img.height * 740) / img.width) / 2 + 10;
    //     } else {
    //       mar_left = (740 - (img.width * 415) / img.height) / 2 + 10;
    //     }
    //     if (img.height / img.width >= 2) {
    //       pix = 0.5;
    //     } else if (img.height / img.width >= 1.5) {
    //       pix = 0.8;
    //     } else if (img.height / img.width >= 1.1) {
    //       pix = 0.9;
    //     }
    //     logo.style.width = 0.88 * pix + "rem";
    //     logo.style.height = 0.22 * pix + "rem";
    //     logo.style.bottom = mar_top / 100 + "rem";
    //     logo.style.right = mar_left / 100 + "rem";
    //   };
    // },
    // 图片下载时合成logo
    // addLogo(src) {
    //   var that = this;
    //   let image = new Image();
    //   image.src = src;
    //   image.setAttribute("crossOrigin", "anonymous");
    //   let canvas = document.createElement("canvas");
    //   let context = canvas.getContext("2d");
    //   let img2 = new Image();
    //   // 将 image 加入画布
    //   img2.src = "";
    //   img2.setAttribute("crossOrigin", "anonymous");
    //   image.onload = () => {
    //     that.canvasWidth = image.width;
    //     that.canvasHeight = image.height;
    //     canvas.width = image.width;
    //     canvas.height = image.height;
    //     context.drawImage(image, 0, 0, image.width, image.height);
    //     img2.width = 88;
    //     img2.height = 22;
    //     // 将 img2 加入画布
    //     img2.onload = () => {
    //       var x = image.width - 88 - 10;
    //       var y = image.height - 22 - 10;
    //       context.drawImage(img2, x, y, img2.width, img2.height);
    //       let src = canvas.toDataURL("image/png");
    //       that.downImg = src;
    //     };
    //   };
    // },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/" + ext);
      return dataURL;
    },
    btof(data, fileName) {
      const dataArr = data.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".jpg", options);
    },
  },
};
</script>
<style scoped lang='less'>
/deep/.el-loading-spinner .path {
  stroke: #babdc5;
}

/deep/.el-loading-mask {
  z-index: 25;
}

/deep/ .el-image__inner {
  object-fit: contain;
}

.humansegmentation {
  max-width: 9.96rem;
  margin: 0 auto;

  .modelBox {
    display: flex;
    height: 0.46rem;
    align-items: center;
    margin-top: 0.3975rem;
    color: #222222;
    font-weight: 400;
    font-size: 0.14rem;

    .modelTitle {
      margin-right: 0.08rem;
    }

    .modelSelect,
    .PitchOn_first,
    .PitchOn_last,
    .PitchOn_Nthone {
      padding: 0.12rem 0.16rem;
      background: #f3f5f8;
      border-radius: 0.02rem;
      margin-left: 0.16rem;
      cursor: pointer;
      box-sizing: border-box;
    }

    .modelSelect:hover {
      color: #2e54eb;
    }

    .modelSelect:active {
      color: #2e54eb;
    }

    .PitchOn_first,
    .PitchOn_last,
    .PitchOn_Nthone {
      border: 0.01rem solid #2e54eb;
      color: #2e54eb;
      background-color: #ecf3ff;
      font-weight: 600;
    }
  }

  .functionBox {
    background-color: #f2f6fa;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2.93rem;
    margin-top: 0.12rem;

    .functionLeft {
      position: relative;
      width: 7.4rem;
      height: 4.15rem;
      border-radius: 0.02rem;
      overflow: hidden;

      .oldimgUrl {
        overflow: hidden;
        position: absolute;
        height: 4.15rem;
        z-index: 11;

        .beforelabel {
          position: absolute;
          width: 0.66rem;
          height: 0.38rem;
          left: 0;
          top: 0;
          background: #00031e;
          opacity: 0.5;
          border-radius: 0.02rem;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 0.14rem;
          line-height: 0.38rem;
          color: #fff;
          text-align: center;
          z-index: 1;
        }

        .imgUrl {
          width: 7.4rem;
          height: 4.15rem;
          object-fit: contain;
        }
      }

      .newimgUrl {
        position: absolute;
        width: 7.4rem;
        height: 4.15rem;
        background-color: #e2e8ef;
        background: url("https://50058.gzc.svp.tencent-cloud.com/0b53diabaaaaomapqz7pqfqyugwdcanaaeaa.f0.jpg?dis_k=6ed76588ad816a371878ebf7caf647d0&dis_t=1643112553")
          no-repeat;
        background-position: center center;

        .imgUrl {
          display: block;
          width: 7.4rem;
          height: 4.15rem;
          object-fit: contain;
        }

        .arcLogo {
          position: absolute;
          width: 0.88rem;
          height: 0.22rem;
          right: 0.1rem;
          bottom: 0.1rem;
        }
      }

      .slideRectangle {
        position: absolute;
        margin-left: -0.38rem;
        z-index: 22;

        .Slice {
          position: absolute;
          bottom: -0.08rem;
          width: 0.76rem;
          height: 0.53rem;

          img {
            width: 0.76rem;
            height: 0.53rem;
          }
        }

        .Rectangle {
          position: absolute;
          left: 0.38rem;
          bottom: -0.2rem;
          width: 1px;
          height: 4.15rem;

          img {
            width: 1px;
            height: 4.15rem;
          }
        }

        .afterlabel {
          width: 0.66rem;
          height: 0.38rem;
          position: absolute;
          top: -3.95rem;
          left: 0.39rem;
          background: #00031e;
          opacity: 0.5;
          border-radius: 0.02rem;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 0.14rem;
          line-height: 0.38rem;
          color: #fff;
          text-align: center;
          z-index: 1;
        }
      }

      /deep/.el-slider {
        margin-top: 3.57rem;

        .el-slider__runway {
          background-color: rgba(255, 255, 255, 0);
        }

        .el-slider__bar {
          background-color: rgba(255, 255, 255, 0);
        }

        .el-slider__button-wrapper {
          width: 0.76rem;

          .el-slider__button {
            z-index: 999;
            width: 0.5rem;
            height: 0.3rem;
            border: 0;
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }
    }

    .functionRight {
      flex: 1;
      padding-left: 0.26rem;
      padding-right: 0.24rem;
      padding-top: 0.23rem;

      .imgList {
        display: block;
        width: 2.06rem;
        height: 1.16rem;
        object-fit: cover;
        box-sizing: border-box;
        border: solid 0.02rem transparent;
      }

      img:nth-child(1) {
        margin-bottom: 0.23rem;
      }

      img:nth-child(2) {
        margin-bottom: 0.23rem;
      }

      .active {
        border: solid 0.02rem #2e54eb;
      }
    }
  }

  .btnBox {
    width: 100%;
    height: 1.06rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    box-sizing: border-box;

    .btnLeft {
      float: left;
      display: flex;

      /deep/.uploadBtn {
        background-color: #2e54eb;
        width: 1.6rem;
        height: 0.46rem;
        line-height: 0.46rem;
        border-radius: 0.02rem;
        color: #fff;
        font-size: 0.16rem;
        text-align: center;
        margin-right: 0.3rem;
        position: relative;
      }
      /deep/.uploadInput {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        cursor: pointer;
        padding: 0.25rem;
      }

      /deep/.uploadBtn:hover {
        background-color: #486dff;
      }

      /deep/.uploadBtn:active {
        background-color: #002acf;
      }

      /deep/.clickupload {
        background-color: #002acf;
      }

      /deep/.dowmBtn,
      /deep/.mouse_disable {
        width: 1.6rem;
        height: 0.46rem;
        line-height: 0.46rem;
        border-radius: 0.02rem;
        font-size: 0.16rem;
        text-align: center;
        border: 1px solid #2e54eb;
        color: #2e54eb;
        cursor: pointer;
      }

      /deep/.dowmBtn:hover {
        color: #fff;
        background-color: #486dff;
        border: 1px solid transparent;
      }

      /deep/.dowmBtn:active {
        color: #fff;
        background-color: #002acf;
        border: 1px solid transparent;
      }
    }

    .btnRight {
      height: 0.46rem;
      flex: 1;
      margin-right: 0.08rem;
      display: flex;
      align-items: center;
      float: right;

      .shareTo {
        font-weight: 600;
        font-family: PingFang SC;
        font-size: 0.14rem;
        line-height: 0.2rem;
        margin-right: 0.22rem;
        color: #222;
      }

      .shareIcon {
        position: relative;
        margin-right: 0.16rem;
        width: 0.28rem;
        height: 0.28rem;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }

        .normal_img {
          display: inline-block;
        }

        .hover_img {
          display: none;
        }

        .wx_code {
          width: 1.52rem;
          height: 1.8rem;
          background: #ffffff;
          border: 1px solid #d9e0e8;
          box-sizing: border-box;
          box-shadow: 0rem 0.04rem 0.15rem rgba(108, 121, 154, 0.201759);
          border-radius: 0.02rem;
          position: absolute;
          top: 0.28rem;
          left: -0.62rem;
          visibility: hidden;

          .code {
            width: 1.2rem;
            height: 1.2rem;
            margin: 0.16rem 0.16rem 0.08rem;
          }

          p {
            height: 0.2rem;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 0.14rem;
            line-height: 0.2rem;
            color: #434343;
            text-align: center;
          }
        }
      }

      .shareIcon:hover {
        .normal_img {
          display: none;
        }

        .hover_img {
          display: inline-block;
        }

        .wx_code {
          visibility: inherit;
        }
      }

      .hover_img:active {
        opacity: 0.7;
      }
    }
  }

  .functionBox_mini {
    margin-top: 0.8rem;

    .message {
      width: 80%;
      margin: 0 auto;
      font-family: PingFangSC-Regular, sans-serif;
      font-size: 0.3rem;
      color: #666666;
      line-height: 0.52rem;
      text-align: center;
    }

    .wxapp_box {
      width: 3.4rem;
      height: 3.4rem;
      margin: 0.4rem auto 0;
      background: #ffffff;
      border: 1px solid #d9e0e8;
      box-sizing: border-box;
      box-shadow: 0px 0.04rem 0.15rem rgb(108 121 154 / 20%);
      border-radius: 0.02rem;
      text-align: center;
      padding-top: 0.2rem;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
</style>